.key__text {
  font-family: "Montserrat";
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  resize: none;
  outline: none;
  padding: 0.4em;
  border-radius: 1.3em;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  background: rgb(0, 0, 0);
  color: white;
  overflow-x: hidden;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;
  padding-right: 16px;
}
