.emotion__icon {
  margin: -10px;
  margin-top: 6px;
  color: rgb(144, 144, 144);
  padding: 0;
}

.button__wrapper {
  display: inline-block;
}
