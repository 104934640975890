.prompt__text {
  font-family: "Montserrat";
  color: white;
  font-weight: lighter;
  font-size: 28px;
  margin-bottom: 16px;
}

.input__text {
  font-family: "Montserrat";
  font-size: 18px;
  box-sizing: border-box;
  width: 100%;
  resize: none;
  outline: none;
  padding: 0.6em;
  border-radius: 2em;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  background: rgb(0, 0, 0);
  color: white;
  overflow-x: hidden;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;
  padding-right: 16px;
}

.input__text__upd {
  font-family: "Montserrat";
  font-size: 18px;
  font-style: italic;
  box-sizing: border-box;
  width: 100%;
  resize: none;
  outline: none;
  padding: 0.6em;
  border-radius: 2em;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  background: rgb(0, 0, 0);
  color: white;
  overflow-x: hidden;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;
  padding-right: 16px;
  pointer-events: none;
}

textarea::-webkit-scrollbar {
  width: 12px;
  right: -100px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: rgb(10, 10, 10);
  outline: 1px solid rgb(197, 197, 197);
  border-radius: 5px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background-color: rgb(31, 31, 31);
}
