* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

p {
  margin: 0;
}

.main__container {
  margin-top: 4%;
}

.logo__row__first {
  margin-top: 20px;
  margin-left: 10px;
}

.logo__row__second {
  margin-top: -10px;
  margin-bottom: 50px;
  margin-left: 35px;
}

.basla_logo {
  width: 340px;
}

.dert_logo {
  width: 90px;
}

.chart__info__row {
  justify-content: end;
  margin-top: 20px;
  margin-right: 8px;
}

.chart__info__text {
  font-family: "Montserrat";
  color: white;
  font-weight: lighter;
  font-size: 28px;
  color: rgb(255, 255, 255);
}

.textfield__row {
  margin-top: 10%;
}

.key__col {
  align-items: center;
}

.col__test {
  margin-top: -0.4%;
  margin-left: -65px;
}

.buttons__row {
  margin-top: 0.3%;
}

.chart__row {
  margin-top: 0.5%;
}

.table__row {
  margin-top: 2%;
}

/* *::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(33, 33, 33);
  outline: 1px solid rgb(197, 197, 197);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(31, 31, 31);
} */

.error__msg {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(233, 233, 233);
  font-family: "Montserrat";
  font-size: 30px;
  margin-top: 1%;
}

.maintenance__container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.maintenance__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.maintenance__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(43, 43, 43, 0.558);
  font-family: "Montserrat";
  color: white;
  font-weight: lighter;
  font-size: 30px;
  color: rgb(255, 255, 255);
}
