/* .upload__button {
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: "Montserrat";
  color: #ffffff;
  font-size: 20px;
  background: #000000;
  padding: 4px 18px 4px 18px;
  border: solid #cccccc 1px;
  text-decoration: none;
}

.upload__button:hover {
  background: #454545;
  text-decoration: none;
} */

.icon__button {
  margin-top: 4px;
  color: white;
  padding: 0;
}

.file__text {
  margin-left: 10px;
  color: white;
  font-size: 14px;
  font-family: "Montserrat";
}

.upload__button__info {
  display: flex;
}

.button__wrapper {
  display: inline-block;
}
