/* .analyze__button {
  padding: 1em;
  border-radius: 1.2em;
  border-width: 0.1em;
  border-style: solid;
  border-color: rgb(144, 144, 144);
  background: rgb(0, 0, 0);
} */

.analyze__button {
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: "Montserrat";
  color: #ffffff;
  font-size: 20px;
  background: #000000;
  padding: 4px 18px 4px 18px;
  border: solid #cccccc 1px;
  text-decoration: none;
}

.loader {
  font-family: "Montserrat";
  color: rgb(128, 205, 195);
  background: #000000;
  text-decoration: none;
}

.analyze__button:hover {
  background: #454545;
  text-decoration: none;
}
